<template>
  <div>
    <section
      class="rounded-md hidden w-256 p-24 lg:block"
      :class="[
        buybackOffer ? 'bg-static-success-low' : 'bg-action-default-brand-mid',
      ]"
    >
      <header class="mb-16 flex items-center gap-12">
        <span class="rounded-md p-8" :class="iconColors">
          <IconCheckLarge v-if="buybackOffer" size="24" />
          <IconSwap v-else size="24" />
        </span>

        <h3 v-if="!buybackOffer" class="heading-3">
          {{ i18n(translations.title) }}
        </h3>
        <div v-if="buybackOffer">
          <h3 class="heading-3">
            {{ i18n.price(buybackOffer?.listing?.price || '') }}
          </h3>
          <p class="caption">{{ i18n(translations.caption) }}</p>
        </div>
      </header>

      <p class="body-2 mb-24">
        {{ blockText }}
      </p>

      <div v-if="!buybackOffer">
        <RevButton
          class="mb-12"
          data-test="swap-plp-cta"
          full-width="always"
          variant="secondary"
          @click="getAnOffer"
        >
          {{ i18n(translations.buttonCta) }}
        </RevButton>
        <button
          class="body-1 w-full text-center underline"
          data-test="swap-plp-info"
          @click="seeHowItWorks"
        >
          {{ i18n(translations.buttonInfo) }}
        </button>
      </div>
      <div v-else>
        <button
          class="body-1 w-full text-center underline"
          data-test="swap-plp-see"
          @click="openViewOfferModal"
        >
          {{ i18n(translations.buttonSee) }}
        </button>
      </div>
    </section>

    <SwapModal
      datalayer-category="TODO"
      :intro="intro"
      :modal-name="MODAL_NAMES.SWAP_LISTING"
      price=""
    />
    <ViewOfferModal
      :modal-name="MODAL_NAMES.SWAP_VIEW_OFFER"
      @edit="openSwapModal"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { tw } from '@backmarket/utils/string/tw'
import { RevButton } from '@ds/components/Button'
import { openModal } from '@ds/components/ModalBase'
import { IconCheckLarge } from '@ds/icons/IconCheckLarge'
import { IconSwap } from '@ds/icons/IconSwap'

import { MODAL_NAMES } from '~/scopes/buyback/constants'
import SwapModal from '~/scopes/buyback/swap/components/SwapModal/SwapModal.vue'

import ViewOfferModal from '../ViewOfferModal/ViewOfferModal.vue'

import translations from './SwapBlockListing.translations'

const i18n = useI18n()
const { buybackOffer } = useBuybackOffer()
const { trackClick, trackSwap } = useTracking()
const intro = ref<'plp'>()

const props = withDefaults(
  defineProps<{
    trackingLabel?: string
  }>(),
  {
    trackingLabel: 'swap_from_plp',
  },
)

const iconColors = computed(() =>
  buybackOffer.value
    ? tw`bg-static-success-low-hover text-action-success-hi`
    : tw`text-static-brand-mid bg-[#E2CCF4]`,
)

const blockText = computed(() =>
  buybackOffer.value
    ? i18n(translations.blockTextWithOffer, {
        device: buybackOffer.value.product.shortTitle || '',
      })
    : i18n(translations.blockTextWithoutOffer),
)

function openSwapModal(introName: 'plp' | undefined = undefined) {
  trackSwap({
    label: props.trackingLabel,
  })

  intro.value = introName

  openModal(MODAL_NAMES.SWAP_LISTING)
}

function openViewOfferModal() {
  trackClick({
    zone: 'product_list',
    name: 'tradein_info',
  })

  openModal(MODAL_NAMES.SWAP_VIEW_OFFER)
}

function getAnOffer() {
  trackClick({
    zone: 'product_list',
    name: 'get_a_tradein_offer',
  })
  openSwapModal()
}

function seeHowItWorks() {
  trackClick({
    zone: 'product_list',
    name: 'tradein_info',
  })
  openSwapModal('plp')
}
</script>
