export default {
  statusOfferData: {
    id: 'view_offer_modal_status_offer_data',
    defaultMessage: 'Get an instant offer',
  },
  statusOfferDate: {
    id: 'view_offer_modal_status_offer_date',
    defaultMessage: 'Today',
  },
  statusTransferData: {
    id: 'view_offer_modal_status_transfer_data',
    defaultMessage: 'Transfer your data',
  },
  statusShipData: {
    id: 'view_offer_modal_status_ship_data',
    defaultMessage: 'Ship for free',
  },
  statusShipDate: {
    id: 'view_offer_modal_status_ship_date',
    defaultMessage: 'Within 21 days',
  },
  statusPaidData: {
    id: 'view_offer_modal_status_paid_data',
    defaultMessage: 'Get paid',
  },
  statusPaidDate: {
    id: 'view_offer_modal_status_paid_date',
    defaultMessage: 'After inspection',
  },
  titleModal: {
    id: 'view_offer_modal_title',
    defaultMessage: 'Trade-in offer',
  },
  edit: {
    id: 'view_offer_modal_edit',
    defaultMessage: 'edit',
  },
  delete: {
    id: 'view_offer_modal_delete',
    defaultMessage: 'Delete trade-in',
  },
  continue: {
    id: 'view_offer_modal_continue',
    defaultMessage: 'Continue Shopping',
  },
  tradeinValue: {
    id: 'view_offer_modal_tradein_value',
    defaultMessage: 'Trade-in value: {price}',
  },
}
