<template>
  <RevContainer v-if="products.length === 0" data-spec="body">
    <NoResult class="my-32" />
  </RevContainer>
  <div v-else>
    <SkinnyBanner page="landing" />
    <RevContainer class="mb-16 mt-32" data-spec="body">
      <div data-spec="main">
        <section data-test="product-results-refinement">
          <div class="mb-12 justify-between lg:mb-32 lg:flex">
            <div class="heading-1">
              {{ i18n(translations.recapTitle) }} <span>{{ query }}</span>
            </div>

            <SortInput
              v-model="sortInput"
              class="hidden basis-200 lg:block"
              :sort-options="sortOptions"
            />
          </div>

          <div class="lg:flex lg:gap-16">
            <aside>
              <SwapBlockListing
                v-if="hasSwapBlock"
                class="mb-32 hidden lg:block lg:w-256"
              />
              <div class="hidden lg:block lg:w-256" data-test="filters-wrapper">
                <PriceFilter
                  v-if="priceFacet"
                  v-model="priceInput"
                  :avgPrice="priceFacet.avg"
                  :currency="defaultCurrency"
                  :max="priceFacet.max"
                  :prices="priceFacet.values"
                  :step="priceFacet.step"
                  variant="default"
                />
                <FacetFilter
                  v-for="facet in facets"
                  :key="facet.name"
                  v-model="filtersInput[facet.name]"
                  class="mt-32"
                  :disabled="false"
                  :name="facet.name"
                  :title="facet.title"
                  tracking="product list > side filter"
                  :type="facet.type"
                  :values="facet.values"
                  variant="default"
                />
              </div>
            </aside>
            <main v-if="products" class="w-full">
              <ActiveFilters
                v-model:filters="filtersInput"
                v-model:price="priceInput"
                class="mb-8 md:mb-32"
                :facets="facets"
                :max-price="priceFacet?.max ?? 0"
                :search-nb-results="total"
              />
              <ResultGrid :products="products" />
            </main>
          </div>

          <Pagination v-model="pageInput" :page-count="pageCount" />
        </section>
      </div>
    </RevContainer>
    <ActionStickyBar class="lg:hidden" :facets-count="facetCount" />
    <FullscreenFilters
      v-if="displayed"
      v-model:filters="filtersInput"
      v-model:price="priceInput"
      v-model:sort="sortInput"
      :facets="facets"
      :price-facet="priceFacet"
      :search-nb-results="total"
      :sort-options="sortOptions"
    />
  </div>
</template>

<script lang="ts" setup>
import { createError, useHead, useRoute, useRuntimeConfig } from '#imports'
import { watch } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevContainer } from '@ds/components/Container'

import SkinnyBanner from '~/scopes/branding/components/SkinnyBanner/SkinnyBanner.vue'
import SwapBlockListing from '~/scopes/buyback/swap/components/SwapBlockListing/SwapBlockListing.vue'
import {
  getSortOptions,
  useAlgoliaApiKey,
  useAlgoliaConfiguration,
} from '~/scopes/search/helpers/helpers'

import { useProductsSearch } from '../../search/composables/useProductsSearch'
import ActionStickyBar from '../components/ActionStickyBar.vue'
import ActiveFilters from '../components/ActiveFilters.vue'
import FullscreenFilters from '../components/FullscreenFilters.vue'
import NoResult from '../components/NoResult.vue'
import Pagination from '../components/Pagination.vue'
import FacetFilter from '../components/Refinements/FacetFilter.vue'
import PriceFilter from '../components/Refinements/PriceFilter.vue'
import ResultGrid from '../components/ResultGrid.vue'
import SortInput from '../components/SortInput.vue'
import { useFullscreenFilters } from '../composables/useFullscreenFilters'
import { useFiltersFromHash } from '../composables/useHashFilters'

import translations from './SearchResultPage.translations'

const i18n = useI18n()

const { defaultCurrency } = useMarketplace().market

const { data: searchConfig } = await useAlgoliaConfiguration('search-landing')
const experiments = useExperiments()

const hasSwapBlock =
  experiments['experiment.discountedPriceInProductCard'] ===
    'withDiscountedPriceOnlyAfter' ||
  experiments['experiment.discountedPriceInProductCard'] ===
    'withDiscountedPrice'

if (searchConfig.value === null) {
  throw createError({
    statusCode: 500,
    statusMessage: 'Unable to retrieve search configuration',
    fatal: true,
  })
}
const { data: apiKeyConfig } = await useAlgoliaApiKey(
  searchConfig.value.indexType,
)

if (apiKeyConfig.value === null) {
  throw createError({
    statusCode: 500,
    statusMessage: 'Unable to get algolia API key',
    fatal: true,
  })
}

const {
  public: { ALGOLIA_ID: appId },
} = useRuntimeConfig()

const sortOptions = getSortOptions(searchConfig.value.indexes)

const route = useRoute()
const initialQueryValue =
  (Array.isArray(route.query.q) ? route.query.q[0] : route.query.q) ?? ''

const {
  total,
  products,
  facets,
  pageCount,
  facetCount,
  priceFacet,
  filtersInput,
  sortInput,
  pageInput,
  priceInput,
  query,
} = await useProductsSearch(
  appId,
  searchConfig.value,
  apiKeyConfig.value.apiKey,
  initialQueryValue,
)

const { displayed } = useFullscreenFilters()

useFiltersFromHash(filtersInput, priceInput, pageInput)

watch(
  () => route.query.q,
  (newQuery) => {
    // react to route changes...
    query.value = (Array.isArray(newQuery) ? newQuery[0] : newQuery) ?? ''
  },
)

const {
  public: { COMPANY_NAME },
} = useRuntimeConfig()
useHead({
  meta: [
    {
      vmid: 'robots',
      name: 'robots',
      content: 'noindex',
    },
  ],
  title: query,
  titleTemplate: `%s | ${COMPANY_NAME}`,
})
</script>
