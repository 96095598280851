<template>
  <RevButtonBase
    class="bg-action-default-mid text-action-default-hi rounded-full body-2 my-12 flex h-36 w-fit shrink-0 items-center px-12 lg:hidden"
    :class="buttonClass"
    @click="handleClick"
  >
    <div class="mr-8 shrink-0">
      <IconSwap :class="iconClass" />
    </div>

    <p class="text-nowrap">
      {{ label }}
    </p>

    <IconChevronRight class="shrink-0" />
  </RevButtonBase>

  <SwapModal
    datalayer-category="TODO"
    :intro="intro"
    :modal-name="MODAL_NAMES.SWAP_LISTING_MOBILE"
    price=""
  />
  <ViewOfferModal
    :modal-name="MODAL_NAMES.SWAP_VIEW_OFFER_MOBILE"
    @edit="openSwapModal"
  />
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { tw } from '@backmarket/utils/string/tw'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { openModal } from '@ds/components/ModalBase'
import { IconChevronRight } from '@ds/icons/IconChevronRight'
import { IconSwap } from '@ds/icons/IconSwap'

import { MODAL_NAMES } from '~/scopes/buyback/constants'
import SwapModal from '~/scopes/buyback/swap/components/SwapModal/SwapModal.vue'
import ViewOfferModal from '~/scopes/buyback/swap/components/ViewOfferModal/ViewOfferModal.vue'

import translations from './SwapPill.translations'

const i18n = useI18n()
const { buybackOffer } = useBuybackOffer()
const { trackSwap, trackClick } = useTracking()
const intro = ref<'plp'>()

const props = withDefaults(
  defineProps<{
    trackingLabel?: string
  }>(),
  {
    trackingLabel: 'swap_from_plp',
  },
)

const iconClass = computed(() => (buybackOffer.value ? tw`, ` : tw``))
const buttonClass = computed(() =>
  buybackOffer.value
    ? tw`bg-action-default-low text-action-success-hi border-1`
    : tw`bg-action-default-brand-mid`,
)
const label = computed(() =>
  buybackOffer.value
    ? i18n(translations.labelWithOffer, {
        price: i18n.price(buybackOffer.value.listing.price),
      })
    : i18n(translations.labelWithoutOffer),
)

function openSwapModal(introName: 'plp' | undefined = undefined) {
  trackSwap({
    label: props.trackingLabel,
  })

  intro.value = introName

  openModal(MODAL_NAMES.SWAP_LISTING_MOBILE)
}

function openViewOfferModal() {
  openModal(MODAL_NAMES.SWAP_VIEW_OFFER_MOBILE)
}

function handleClick() {
  if (buybackOffer.value) {
    openViewOfferModal()

    return
  }

  trackClick({
    zone: 'product_list',
    name: 'tradein_info',
  })

  openSwapModal('plp')
}
</script>
